import React from 'react';
import './App.css';
import maxteria from './img/maxteria-avatar.png';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={maxteria} alt="Maxteria" className='Maxteria-avatar' />
        <h1>Maxteria</h1>
        <p>The lab is working!</p>
      </header>
    </div>
  );
}

export default App;
